<template>
  <div class="feedback">
    <el-form
      ref="form"
      :model="form"
      label-width="110px"
      label-position="left"
      :rules="rules"
    >
      <el-form-item label="意见反馈：">
        <el-input
          type="textarea"
          v-model="form.content"
          rows="5"
          placeholder="请输入您的反馈内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="补充图片：">
        <upload-img @input="getimg"></upload-img>
      </el-form-item>
      <el-form-item label="联系电话：" >
        <el-input
          v-model="form.tel"
          placeholder="请输入联系电话"
          type="text"
          class="input-y"
          clearable
        >
        </el-input>
      </el-form-item>
      <div class="flex flexcenter">
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import uploadImg from "@c/common/uploadimg.vue";
export default {
  data() {
    // 这里存放数据
    return {
      form: {
        content: "",
        tel: "",
        images: [],
      },
      rules: {
        tel: [
          {
            validator: this.validatePhone,
            message: "号码不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
  },
  components: { uploadImg },
  mounted() {},
  methods: {
    getimg(str) {
      this.form.images = str.split(",");
    },
    validatePhone(rule, value, callback) {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号不正确！"));
      } else {
        callback();
      }
    },
    ...mapMutations([
      "setLogin",
      "setUserInfo",
      "setLoginDialog",
      "setRegistDialog",
    ]),
   openpage(name,num){
         this.$router.push({
          name,
          params: {
            num
          },
        })
    },
    onSubmit() {
      this.$http.feedback(this.form).then((res) => {
        if (res.code == 0) {
           this.$message.error({ message: res.msg });
        } else if (res.code == 1) {
            this.$message.success({ message: "提交成功!" });
            this.openpage("我发布的需求",0);
        }
      }); 
    },
  },
};
</script>
<style scoped lang="scss">
.el-button {
  font-size: 16px;
  width: 200px;
  margin-top: 30px;
}
.feedback {
  padding: 40px;
}
.el-form {
  /deep/.el-form-item__label {
    font-size: 18px;
    color: #333;
  }
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
}
/deep/.el-upload--picture-card {
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  cursor: pointer;

  vertical-align: top;
  border: none;
  background: none;
}
.addpic {
  width: 120px;
  height: 120px;
  background-color: #f1f1f1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  img {
    display: block;
  }
  .word {
    color: #999;
    font-size: 20px;
    line-height: 30px;
  }
}
</style>
